import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Login from '../views/login'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/news-center',
    children: [
      {
        path: '/news-center',
        name: 'news-center',
        component: () => import('../views/newsCenter/index')
      },
      {
        path: '/case-center',
        name: 'case-center',
        component: () => import('../views/caseCenter/index')
      },
      {
        path: '/recruit-center',
        name: 'recruit-center',
        component: () => import('../views/recruitCenter/index')
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  }
]

const router = new VueRouter({
  routes
})

export default router
