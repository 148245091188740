import Vue from 'vue'

import {
  Button,
  Upload,
  Dropdown,
  Avatar,
  Form,
  FormModel,
  Modal,
  Popconfirm,
  Input,
  Select,
  notification,
  message,
  Table,
  Icon,
  Layout,
  Menu,
  DatePicker,
  ConfigProvider,
  Divider,
  Card,
  Drawer,
  Spin,
  Tag,
  InputNumber
} from 'ant-design-vue'
import MyIcon from '@/components/icon/myIcon'
import moment from 'moment'
import 'moment/locale/zh-cn'


moment.locale('zh-cn')
Vue.component(MyIcon.name, MyIcon)
Vue.use(InputNumber)
Vue.use(Button)
Vue.use(Tag)
Vue.use(Upload)
Vue.use(Dropdown)
Vue.use(Avatar)
Vue.use(Popconfirm)
Vue.use(Spin)
Vue.use(Drawer)
Vue.use(Card)
Vue.use(Divider)
Vue.use(Form)
Vue.use(FormModel)
Vue.use(Modal)
Vue.use(Input)
Vue.use(Select)
Vue.use(Table)
Vue.use(Icon)
Vue.use(Layout)
Vue.use(Menu)
Vue.use(DatePicker)
Vue.use(ConfigProvider)

Vue.prototype.$notification = notification
Vue.prototype.$message = message
Vue.prototype.$confirm = Modal.confirm
