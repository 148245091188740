import moment from 'moment'
import { ACCESS_TOKEN } from '@/store/mutation-types'

export function timeFix() {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}



/**
 * 过滤对象中为空的属性
 * @param obj
 * @returns {*}
 */
export function filterObj(obj) {
  if (typeof obj !== 'object') {
    return
  }
  const result = {}
  for (const key in obj) {
    if (obj.hasOwnProperty(key) &&
      (obj[key] !== null && obj[key] !== undefined && obj[key] !== '')) {
      result[key] = obj[key]
    }
  }
  return result
}

/**
 * 深度克隆对象、数组
 * @param obj 被克隆的对象
 * @return 克隆后的对象
 */
export function cloneObject(obj) {
  return JSON.parse(JSON.stringify(obj))
}

export function isIE() {
  const bw = window.navigator.userAgent
  const compare = (s) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

export function uuid() {
  var s = []
  var hexDigits = '0123456789abcdef'
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4'  // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)  // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-'

  var uuid = s.join('')
  return uuid
}

export function getUrlQueryByKey(key) {
  const href = decodeURIComponent(location.href)
  return decodeURIComponent((new RegExp('[?|&]' + key + '=' + '([^&;]+?)(&|#|;|$)').exec(href) || [, ''])[1].replace(/\+/g, '%20')) || null
}


/**
 * 是否为手机号
 * 13012345678
 * @param {*} data
 * @returns
 */
export const phoneReg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
export function isPhone(data) {
  if (!phoneReg.test(data)) {
    return false
  } else {
    return true
  }
}

/**
 * 是否为邮箱
 * 123@qq.com
 * @param {*} data
 * @returns
 */
export function isEmail(data) {
  const reg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
  if (!reg.test(data)) {
    return false
  } else {
    return true
  }
}

/**
 * 是否为正整数
 * 1、2、10
 * @param {*} data
 * @returns
 */
export const isPosIntNum = (value) => {
  const reg = /^[1-9]\d*$/
  if (reg.test(value)) {
    return true
  } else {
    return false
  }
}

export function toChinesNum(num) {
  const changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
  const unit = ['', '十', '百', '千', '万']
  num = parseInt(num)
  const getWan = (temp) => {
    const strArr = temp.toString().split('').reverse()
    let newNum = ''
    for (var i = 0; i < strArr.length; i++) {
      newNum = (i == 0 && strArr[i] == 0 ? '' : (i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ? '' : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i]))) + newNum
    }
    return newNum
  }
  const overWan = Math.floor(num / 10000)
  let noWan = num % 10000
  if (noWan.toString().length < 4) { noWan = '0' + noWan }
  return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num)
}

export function triggerClick (node) { // 手动触发dom的click事件
  try {
    node.dispatchEvent(new MouseEvent('click'))
  } catch (e) {
    var evt = document.createEvent('MouseEvents')
    evt.initMouseEvent('click', true, true, window, 0, 0, 0, 80,
                          20, false, false, false, false, 0, null)
    node.dispatchEvent(evt)
  }
}

export function random (min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const underReg = /-/g
export function mapKeyProp (data, keyString, defaultValue = '--', replacer) { // 获取对象的值 const data = {test: {test: 'xxx'}} mapKeyProp(data, 'test.test')
  if (!keyString || !data) return (obj === undefined || obj === '--' || obj === '----' || obj === 'NaN' || obj === 'null' || obj === 'undefined' || obj === null) ? defaultValue : obj
  const keyChain = keyString.split('.').map(str => str.replace(underReg, '.'))
  let obj = data
  if (keyChain.length === 1) {
    obj = data[keyString]
  } else {
    keyChain.forEach(key => {
      if (obj) {
        obj = obj[key]
      }
    })
  }
  obj = (obj === undefined || obj === '--' || obj === '----' || obj === 'NaN' || obj === 'null' || obj === 'undefined' || obj === null) ? defaultValue : obj
  if (replacer) {
    if (typeof replacer === 'function') {
      obj = replacer(obj)
    } else {
      if (replacer.trueString && replacer.falseString) { // 两种状态的值
        const trueValue = replacer.trueValue
        const falseValue = replacer.falseValue
        if (obj !== defaultValue) {
          if (trueValue !== undefined && falseValue !== undefined) {
            if (obj === trueValue) {
              obj = replacer.trueString
            } else if (obj === falseValue) {
              obj = replacer.falseString
            }
          } else {
            obj = obj ? replacer.trueString : replacer.falseString
          }
        }
      }
    }
  }
  return obj
}
export function createFileInput (attrs = {}, {fileChange, autoTrigger} = {}) { // 生成file input 并打开文本框
  fileChange = fileChange || function () {}
  const input = document.createElement('input')
  input.type = 'file'
  input.style.display = 'none'
  Object.keys(attrs).forEach(key => {
    input.setAttribute(key, attrs[key])
  })
  input.onchange = function (e) {
    fileChange(e)
    input.onchange = null
    document.body.removeChild(input)
  }
  if (autoTrigger) {
    triggerClick(input)
  }
  document.body.appendChild(input)
  return input
}

export function duplicate (arr, getCompare) { // 去重
  getCompare = getCompare || (key => key)
  const hash = {}
  const results = []
  arr.forEach(item => {
    const key = getCompare(item)
    if (!hash[key]) {
      hash[key] = true
      results.push(item)
    }
  })
  return results
}

export function isObject (obj) { // 判断是否是对象
  return Object.prototype.toString.call(obj) === '[object Object]'
}

// 获取token
export const getToken = () => {
  return localStorage.getItem(ACCESS_TOKEN)
} 

// 设置token
export const setToken = (token) => {
  return localStorage.setItem(ACCESS_TOKEN, token)
}

// 清除token
export const removeToken = (token) => {
  return localStorage.removeItem(ACCESS_TOKEN)
}
const imgStrReg = /<img[\s\S]+src=\"([\s\S]+)\"[^\>]+>/g
export function getImgSourcesFromStr () {
  const div = document.createElement('div')
  return function (str) {
    if (!str) return []
    div.innerHTML = str
    const imgUrls = [...div.getElementsByTagName('img')].map(img => img.getAttribute('src'))
    return imgUrls
  }
}
export function getFilename (url) {
  if (!url) return ''
  const paths = url.split('/')
  return paths[paths.length - 1] || ''
}
const numReg = /^[0-9]+$/
export function isNumber (n) {
  return numReg.test(n)
}
