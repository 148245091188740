
<template>
  <my-icon v-bind="$attrs" v-on="$listeners" :type="type"/>
</template>

<script>
import { Icon } from 'ant-design-vue'
const MyIcon = Icon.createFromIconfontCN({
  scriptUrl: require('@/assets/fonts/iconfont/iconfont')
  // scriptUrl: '//at.alicdn.com/t/font_2054584_0dt3e6aey3y.js'
})
export default {
  inheritAttrs: false,
  name: 'MyIcon',
  components: {
    'my-icon': MyIcon
  },
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data () {
    return {

    }
  }
}
</script>

<style scoped>

</style>
