import { ACCESS_TOKEN } from '@/store/mutation-types'
export function setUserInfo (data) {
  window.localStorage.setItem('userInfo', JSON.stringify(data))
}
export function getUserInfo () {
  let data = window.localStorage.getItem('userInfo')
  try {
    data = JSON.parse(data)
  } catch (e) {
    //TODO handle the exception
    data = {}
  }
  return data
}

// 获取token
export const getToken = () => {
  return localStorage.getItem(ACCESS_TOKEN)
} 

// 设置token
export const setToken = (token) => {
  return localStorage.setItem(ACCESS_TOKEN, token)
}

// 清除token
export const removeToken = (token) => {
  return localStorage.removeItem(ACCESS_TOKEN)
}