/* eslint-disable */
import axios from 'axios'
import router from '@/router'
import store from '@/store'
// import { Notification, MessageBox } from 'element-ui'
import { notification, Modal } from 'ant-design-vue'
// import store from '@/store'
import { getToken, removeToken } from '@/utils/util'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, // api 的 base_url
  timeout: 60000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers['Authorization'] = `${getToken()}` // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    config.headers['Content-Type'] = 'application/json'
    config.headers['Accept'] = 'application/json'
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    const code = response.status
    if (code < 200 || code > 300) {
      notification.error({
        message: response.message
      })
      return Promise.reject('error')
    } else {
      const data = response.data
      if (data.code === 401) {
        notification.error({
          message: '登录过期！请重新登录'
        })
        store.dispatch('Logout')
        router.replace({
          path: '/login',
          query: {
            redirect: encodeURIComponent(router.currentRoute.path)
          }
        })
        return Promise.reject()
      } else if (data.code !== 200) {
        notification.error({
          message: data.message
        })
        return Promise.reject()
      }
      return response.data
    }
  },
  err => {
    notification.error({
      message: err.message
    })
  }
)
export default service
