<template>
  <a-layout id="components-layout-demo-custom-trigger">
    <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
      <div class="logo">
        <img class="app-logo-img" src="@/assets/img/logo.png" alt="">
      </div>
      <a-menu theme="dark" mode="inline" :selectedKeys="[$route.path]" @click="handleLink">
        <a-menu-item v-for="menuItem in menus" :key="menuItem.path">
          <my-icon :type="menuItem.icon" />
          <span>{{ menuItem.name }}</span>
        </a-menu-item>
      </a-menu>
      <div class="layout-link-container">
        <a href="https://www.aihmbj.com" target="_blank">
          <a-button>跳转到官网</a-button>
        </a>
      </div>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <a-icon
          class="trigger"
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="() => (collapsed = !collapsed)"
        />
        <a-dropdown class="head-right">
          <span class="action ant-dropdown-link user-dropdown-menu" style="cursor: pointer;">
            <a-avatar style="vertical-align: middle; margin-right: 5px;" class="avatar" size="small" :src="avatar" />
            <span style="vertical-align: middle;">{{ userInfo.name }}</span>
          </span>
          <a-menu slot="overlay" class="user-dropdown-menu-wrapper">
            <a-menu-item key="3">
              <a href="javascript:;" @click="handleLogout">
                <a-icon type="logout" style="margin-right: 5px;" />
                <span>退出登录</span>
              </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </a-layout-header>
      <a-layout-content
        :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '280px', overflow: 'auto' }"
      >
        <router-view />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import { userLogin } from '@/api'
import { removeToken } from '@/utils/util'

export default {
  data() {
    return {
      collapsed: false,
      avatar: '',
      menus: [
        {
          path: '/news-center',
          name: '新闻中心',
          icon: 'iconxinwen'
        },
        {
          path: '/case-center',
          name: '案例中心',
          icon: 'iconanli'
        },
        {
          path: '/recruit-center',
          name: '招聘中心',
          icon: 'iconzhaopin'
        }
      ]
    }
  },
  computed: {
    userInfo () {
      return JSON.parse(localStorage.getItem('userInfo')) || {}
    }
  },
  methods: {
    handleLink (item) {
      this.$router.push(item.key)
    },
    // 退出登陆
    handleLogout() {
      this.$confirm({
        title: '提示',
        content: '真的要注销登录吗 ?',
        onOk: () => {
          this.$store.dispatch('Logout')
          this.$message.success('退出成功！')
          this.$router.push('/login')
        },
        onCancel() { }
      })
    }
  }
}
</script>
<style lang="less">
#components-layout-demo-custom-trigger {
  height: 100%;
  .app-logo-img {
    width: 60px;
  }
  .ant-layout-sider-collapsed {
    .app-logo-img {
      width: 40px;
    }
  }
  .layout-link-container {
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    text-align: center;
  }
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  text-align: center;
  margin: 16px;
}
.head-right{
  float: right;
  padding-right: 20px;
}
</style>

