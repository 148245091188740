import axios from '@/utils/request'

// 用户登陆
export function userLogin(params) {
  return axios({
    url: '/wiki/management/login',
    method: 'post',
    data: params
  })
}

// 新闻列表
export function getNewsList(params) {
  return axios({
    url: '/wiki/manager/articles',
    method: 'get',
    params: params
  })
}

// 新闻详情
export function getNewsDetail(params) {
  return axios({
    url: `/wiki/manager/article/${params.articleId}`,
    method: 'get'
  })
}


// 新闻类型列表
export function getNewsTypeList() {
  return axios({
    url: '/wiki/article/type',
    method: 'get'
  })
}

// 新闻状态列表
export function getNewsStatusList() {
  return axios({
    url: '/wiki/article/state',
    method: 'get'
  })
}

// 新闻置顶
export function setNewsTop(params) {
  return axios({
    url: `/wiki/manager/article/${params.id}/stick`,
    method: 'post',
    data: params
  })
}

// 新闻删除
export function delNews(params) {
  return axios({
    url: `/wiki/manager/article/${params.id}`,
    method: 'post',
    data: params
  })
}

// 获取阿里云相关参数
export function aliyunOssUrl() {
  return axios({
    url: `/oss/policy`,
    method: 'get'
  })
}

// 阿里云上传图片
export function aliyunUploadUrl(params) {
  return axios({
    url: `/oss/file`,
    method: 'post',
    data: params
  })
}

// 创建一个富文本编辑器所需的自定义上传文件的函数
export function createUploadHandleForTinymc () {
  return function (blobInfo, success, failure) {
    const formData = new FormData()
    formData.append('file', blobInfo.blob())
    aliyunUploadUrl(formData)
      .then(data => {
        success(data.data)
      })
      .catch(e => {
        failure(e.message)
      })
  }
}

// 新增/修改新闻
export function saveNews(params) {
  return axios({
    url: `/wiki/manager/article/${params.articleId}`,
    method: 'put',
    data: params
  })
}

// 案例列表
export function getCaseList(params) {
  return axios({
    url: '/wiki/manager/casus/list',
    method: 'get',
    params: params
  })
}

// 案例类型列表
export function getCaseTypeList() {
  return axios({
    url: '/wiki/casus/type',
    method: 'get'
  })
}

// 案例状态列表
export function getCaseStatusList() {
  return axios({
    url: '/wiki/casus/state',
    method: 'get'
  })
}

// 案例置顶
export function setCaseTop(params) {
  return axios({
    url: `/wiki/manager/casus/${params.id}/stick`,
    method: 'post',
    data: params
  })
}

// 案例删除
export function delCase(params) {
  return axios({
    url: `/wiki/manager/casus/${params.id}`,
    method: 'post',
    data: params
  })
}

// 新增/修改案例
export function saveCase(params) {
  return axios({
    url: `/wiki/manager/casus/${params.articleId}`,
    method: 'put',
    data: params
  })
}

// 后台管理-招聘列表
export function getJobsList (params) {
  return axios({
    method: 'get',
    url: '/wiki/manager/jobs',
    params: params
  })
}

// 后台管理-新增或修改招聘
export function saveJob ({ jobId, ...data }) {
  return axios({
    method: 'put',
    url: `/wiki/manager/job/${jobId}`,
    data: data
  })
}

// 招聘类型列表
export function getJobType () {
  return axios({
    method: 'get',
    url: '/wiki/job/type'
  })
}

// 后台管理-删除招聘
export function deleteJob ({ jobId }) {
  return axios({
    method: 'post',
    url: `/wiki/manager/job/${jobId}`
  })
}


// 后台管理-案例置顶或取消置顶操作
export function setJobTop ({ jobId, ...data }) {
  return axios({
    method: 'post',
    url: `/wiki/manager/job/${jobId}/stick`,
    data
  })
}
// 修改案例权重
export function changeCaseWeight (data) {
  return axios({
    method: 'post',
    url: `/wiki/manager/casus/${data.id}/weight`,
    data
  })
}
