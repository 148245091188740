import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import '@/router/permission' // permission control
import store from '@/store'
import '@/theme/index.less'
import '@/antd/antd-ui'
import '@/global.less'
Vue.config.productionTip = false

Vue.prototype.$axios = axios

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
