<template>
  <div class="login-page">
    <div class="login-wrapper">
      <div class="login-header">
        <img class="logo" src="~@/assets/img/logo.png" alt="">
        <h1 class="title">安得生后台管理系统</h1>
      </div>
      <div class="form-container">
        <a-form-model
          :model="formData"
          :rules="rules"
          ref="form"
        >
          <a-form-model-item prop="username">
            <a-input
              v-model="formData.username"
              placeholder="请输入用户名"
              size="large"
            >
              <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
            </a-input>
          </a-form-model-item>
          <a-form-model-item prop="password">
            <a-input
              v-model="formData.password"
              placeholder="请输入密码"
              type="password"
              size="large"
            >
              <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)" />
            </a-input>
          </a-form-model-item>
          <a-form-model-item>
            <a-button @click="login" size="large" block type="primary">登录</a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </div>
</template>

<script>
import { userLogin } from '@/api'
import { setToken } from '@/utils/util'
import {
  SET_TOKEN,
  SET_USER_INFO
} from '@/store/mutation-types'

export default {
  data () {
    return {
      formData: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          {
            required: true,
            message: '用户名不能为空'
          }
        ],
        password: [
          {
            required: false,
            message: '密码不能为空'
          }
        ]
      }
    }
  },
  methods: {
    login () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            workNum: this.formData.username,
            password: this.formData.password
          }
          userLogin(params).then(data => {
            if (data.code === 200) {
              setToken(data.data.token)
              localStorage.setItem('userInfo', JSON.stringify(data.data.user))
              this.$router.push({ path: '/' })
              this.$message.success('登陆成功！')
            } else {
              this.$message.error('登陆失败！')
            }
          })
        } else {
          
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields();
    }
  }
}
</script>

<style lang="less">
.login-page {
  height: 100vh;
  background-image: url('~@/assets/img/background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f0f2f5;
  display: flex;
  justify-content: center;
  align-items: center;
  .login-wrapper {
    width: 368px;
  }
  .login-header {
    text-align: center;
  }
  .logo {
    width: 67px;
    vertical-align: middle;
    margin-bottom: 10px;
  }
  .title {
    font-size: 33px;
    color: rgba(0,0,0,.85);
    font-family: Avenir,Helvetica Neue,Arial,Helvetica,sans-serif;
    font-weight: 600;
    text-align: center;
  }
}
</style>
