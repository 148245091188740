import { 
  ACCESS_TOKEN,
  SET_TOKEN,
  SET_USER_INFO,
  REMOVE_TOKEN
} from '@/store/mutation-types'
import { setToken, getToken, removeToken, setUserInfo, getUserInfo } from '@/utils/auth'

export default {
  state: {
    token: getToken(),
    userInfo: localStorage.getItem('userInfo') || {}
  },
  mutations: {
    [SET_TOKEN] (state, token) {
      setToken(token)
      state.token = token
    },
    [SET_USER_INFO] (state, userInfo) {
      state.userInfo = userInfo
    },
    [REMOVE_TOKEN] (state) {
      state.token = undefined
      removeToken()
    }
  },
  actions: {
    Login ({ commit }, data) {
      setToken(data.data.token)
      localStorage.setItem('userInfo', JSON.stringify(data.data.user))
      commit(SET_TOKEN, data.data.token)
      commit(SET_USER_INFO, data.data.user)
    },
    Logout ({ commit }) {
      commit(REMOVE_TOKEN)
      commit(SET_USER_INFO, {})
    }
  }
}